<!-- 批量上传面单 -->
<template>
<div class="content-panel">
  <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
    <el-col :span="20">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">{{msg('首页')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{msg('只上传面单')}}</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>
  </el-row>

  <el-row :gutter="10" type="flex" justify="space-between" align="center" class="ctrl-wrap">
    <el-col :span="12">
        <el-upload class="upload-demo" :with-credentials="true" multiple
            :file-list="fileList"
            :before-upload="handleBeforeUpload"
            :data="pdfdata"
            :action="uploadPdfs" :on-success="handleSuccess">
              <el-button size="small" icon="el-icon-upload2" type="primary">{{msg('批量上传面单')}}</el-button>
        </el-upload>
    </el-col>
    <el-col :span="12">
      <el-button size="small" icon="el-icon-upload2" type="primary" @click="doExport()">{{msg('导出结果')}}</el-button>
    </el-col>
  </el-row>
 
  <div class="table-wrap">
    <!-- <el-table :data="results" style="width: 100%" height="400px" size="mini" ref="dataTable" stripe>
      <el-table-column type="index" width="50"></el-table-column>
      <el-table-column prop="filename" :label="msg('文件名')" min-width="120" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="ordercode" :label="msg('订单编号')" min-width="120" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="msg" :label="msg('上传结果')" min-width="200"></el-table-column>
    </el-table> -->
    <vxe-grid ref="dataTable" height="400px" 
          resizable border export-config
          show-overflow highlight-hover-row
          show-footer
          highlight-current-row highlight-hover-column highlight-current-column
          :data="results"
          :columns="columns"
      ></vxe-grid>
  </div>
  <el-row :gutter="10" type="flex" justify="start" align="center" class="ctrl-wrap">
    <el-col :span="24">
        <el-alert :title="msg('upload-page-warning1')"  type="warning"></el-alert>
        <el-alert :title="msg('upload-page-warning2')"  type="warning"></el-alert>
        <el-alert :title="msg('upload-page-warning3')"  type="warning"></el-alert>
    </el-col>
  </el-row>
</div>
</template> 

<script>
// @ is an alias to /src

export default {
  name: 'ExpOnlyPageImport',
  components: {},
  mounted:function(){
    var _this = this;
    this.initColumns();
  },
  data(){
    return{
      uploadPdfs: this.$kit.api.expOnlyUploadPdfs,
      results: [],   //表格数据列表
      fileList: [],
      columns:[],
      pdfdata:{
        code:null,
        goodName: null,
      },
    }
  }, 
  computed: {
    isMinScreen: {  //是否小屏幕，浏览器
        get() {
          return this.$store.state.isMinScreen;
        }
    },
    docHeight: {  //表格的高度
        get() {
          var height =  this.$store.state.docHeight;
          return height - 320;
        }
    },
  },
  methods: {
    initForm(){
        this.results.splice(0);
        this.fileList.splice(0);
    },
    initColumns(){
            this.columns = [
                {type: "seq", title: this.msg("序号"), minWidth: 60,  },
                {field: "filename", title: this.msg("文件名"), minWidth: 120,  },
                {field: "ordercode", title: this.msg("订单编号"), minWidth: 120,  },
                {field: "msg", title: this.msg("上传结果"), minWidth: 200,  },
            ]
        },
    handleSuccess(res, file, fileList) {
        if(res.status){
            var data = res.data;
            this.results.push(data);
        }else{
          this.$message(res.msg || this.msg("上传失败"));
        }
    },
    doExport(){
        this.$refs.dataTable.openExport({ isPrint: false })
    },
    handleBeforeUpload(file){
      var filename = file.name;
      var name = filename.substring(0, filename.lastIndexOf("."));
      
      var temps = name.split("+");

      name = temps[0].replace(/\s+/g, "");

      // if(/^\w+$/.test(name)){
      //   this.pdfdata.code= name;
      //   if(temps.length>1){
      //     this.pdfdata.goodName = temps[1];
      //   }
      //   return true;
      // }else{
      //   this.$message(this.msg("文件名的单号不合法"));
      //   return false;
      // }
      this.pdfdata.code= name;
        if(temps.length>1){
          this.pdfdata.goodName = temps[1];
        }
      return true;
 
    }
  }
} 
</script>

<style class="scss" scoped>


</style>
