<template>
<div class="content-panel">
  <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
    <el-col :span="20">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">{{msg('首页')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{msg('物流信息管理')}}</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>
    
  </el-row>

  <el-row :gutter="10"  class="query-wrap" :style="{height: isQueryExpend?'auto':'55px'}">
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  :placeholder="msg('物流信息编号')" v-model="query.expCode" size="small">
          <i slot="prefix" class="el-input__icon el-icon-tickets"></i>
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  :placeholder="msg('袋号')" v-model="query.pkgCode" size="small">
          <i slot="prefix" class="el-input__icon el-icon-suitcase"></i>
        </el-input>
    </el-col> 
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  :placeholder="msg('收件人')" v-model="query.receiver" size="small">
          <i slot="prefix" class="el-input__icon el-icon-user"></i>
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  :placeholder="msg('店铺')" v-model="query.shopName" size="small">
          <i slot="prefix" class="el-input__icon el-icon-office-building"></i>
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  :placeholder="msg('店铺ID')" v-model="query.shopId" size="small">
          <i slot="prefix" class="el-input__icon el-icon-office-building"></i>
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4" clearable >
        <el-select v-model="query.expCompany" size="small" placeholder="物流厂商" class="query-select" clearable>
          <el-option v-for="d in dicts.expCompanies" :key="d.value" :label="d.label" :value="d.value"></el-option>
        </el-select>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
      <el-select v-model="query.status" size="small" placeholder="物流状态" class="query-select" clearable>
        <el-option v-for="(status, idx) in dicts.expStatus" :label="status" :value="idx" :key="idx"></el-option>
      </el-select>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  :placeholder="msg('面单码')" v-model="query.expPageCode" size="small">
          <i slot="prefix" class="el-input__icon el-icon-tickets"></i>
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  :placeholder="msg('台湾物流单号')" v-model="query.twExpCode" size="small">
          <i slot="prefix" class="el-input__icon el-icon-tickets"></i>
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  :placeholder="msg('创建人')" v-model="query.createrName" size="small">
          <i slot="prefix" class="el-input__icon el-icon-user"></i>
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
       <el-select v-model="query.pagePrintStatus" size="small" class="query-select" placeholder="打印状态" clearable>
          <el-option v-for="(d, idx) in dicts.expPageStatus1" :key="idx" :label="d.label" :value="d.value"></el-option>
        </el-select>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
       <el-date-picker  v-model="query.Date"  type="daterange"  :range-separator="msg('至')" 
            :start-placeholder="msg('创建日期')"  value-format="yyyy-MM-dd"
            end-placeholder=""
            size="small"
          >
        </el-date-picker>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
      <el-select v-model="query.allowUploadPage" class="query-select" placeholder="是否允许上传面单" size="small" clearable>
        <el-option v-for="(d, idx) in dicts.allowUploadPages1" :key="idx" :label="d.label" :value="d.value"></el-option>
      </el-select>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
      <el-select v-model="query.hasWeight" class="query-select" :placeholder="msg('有无重量')" size="small" clearable>
        <el-option :label="msg('有重量')" :value="1"></el-option>
        <el-option :label="msg('无重量')" :value="0"></el-option>
      </el-select>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
      <el-select v-model="query.hasStorePkg" class="query-select" placeholder="打包类型" size="small" clearable>
        <el-option v-for="(d, idx) in dicts.expPkgTypes1" :key="idx" :label="d.label" :value="d.value"></el-option>
      </el-select>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
      <el-select v-model="query.packaged" class="query-select" placeholder="是否有包号" size="small" clearable>
        <el-option v-for="(d, idx) in dicts.packageds" :key="idx" :label="d.label" :value="d.value"></el-option>
      </el-select>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
      <el-select v-model="query.pageType" class="query-select" placeholder="订单来源" size="small" clearable>
        <el-option v-for="(d, idx) in dicts.expOrigins1" :key="idx" :label="d.label" :value="d.value"></el-option>
      </el-select>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
      <el-input  :placeholder="msg('报单备注1')" v-model="query.remark1" size="small">
          <i slot="prefix" class="el-input__icon el-icon-tickets"></i>
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
       <el-date-picker  v-model="query.pickUpDate"  type="daterange"  :range-separator="msg('至')" 
            :start-placeholder="msg('提货日期')"  value-format="yyyy-MM-dd"
            end-placeholder=""
            size="small"
          >
        </el-date-picker>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
       <el-date-picker  v-model="query.printDate"  type="daterange"  :range-separator="msg('至')" 
            :start-placeholder="msg('打印面单日期')"  value-format="yyyy-MM-dd"
            end-placeholder=""
            size="small"
          >
        </el-date-picker>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
       <el-date-picker  v-model="query.printTicketDate"  type="daterange"  :range-separator="msg('至')" 
            :start-placeholder="msg('打印货单日期')"  value-format="yyyy-MM-dd"
            end-placeholder=""
            size="small"
          >
        </el-date-picker>
    </el-col>
  </el-row>
  <vxe-toolbar id="toolbar13" :custom="{storage: true}">
    <template v-slot:buttons>
      <el-button-group>
        <el-button type="primary" size="mini" @click="add()" v-if="hasPermission('express:create')">
          <i class="el-icon-circle-plus-outline" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('添加')}}</span>
          </transition>
        </el-button>
        <el-button type="primary" size="mini" @click="addUnderline()" v-if="hasPermission('express:createUnderline')">
          <i class="el-icon-circle-plus-outline" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('添加线下资料')}}</span>
          </transition>
        </el-button>
        <el-dropdown  @command="clickPageItem" v-if="hasPermissions(['express:pageUpload', 'express:timeOut'])">
          <el-button type="primary" size="mini" icon="el-icon-upload">
            <transition name="el-fade-in-linear">
              <span v-show="!isMinScreen">{{msg('面单操作')}}</span>
            </transition>
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="page-upload" v-if="hasPermission('express:pageUpload')">{{msg('面单批量上传')}}</el-dropdown-item>
            <el-dropdown-item command="only-page-upload" v-if="hasPermission('express:onlyPageUpload')">{{msg('只上传面单')}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-button type="primary" size="mini" @click="edit()"  v-if="hasPermission('express:update')">
          <i class="el-icon-edit" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('编辑')}}</span>
          </transition>
        </el-button>
        <el-button type="primary" size="mini" @click="autoResend()" v-if="hasPermission('store:autoResend')">
          <i class="el-icon-refresh-right" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('现货转寄')}}</span>
          </transition>
        </el-button>
        <el-button type="success" size="mini" @click="batchSend()" v-if="hasPermission('shop:batchSend')">
          <i class="el-icon-s-promotion" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('一键发货')}}</span>
          </transition>
        </el-button>
        <el-button type="danger" size="mini" @click="deleted()" v-if="hasPermission('express:delete')">
          <i class="el-icon-delete" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('删除')}}</span>
          </transition>
        </el-button>
        <el-button type="success" size="mini" @click="uploadWeight()" v-if="hasPermissions(['express:expWeightImport', 'express:expOrderStatusImport'], 'or')">
          <i class="el-icon-upload2" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('导入更新')}}</span>
          </transition>
        </el-button>
        <el-button type="primary" size="mini" @click="notifyUpPage(1)" v-if="hasPermission('express:notifyUpPage')">
          <i class="el-icon-bell" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('通知上传面单')}}</span>
          </transition>
        </el-button>
        <el-dropdown  @command="clickProcessItem" v-if="hasPermissions(['express:cancel', 'express:timeOut'])">
          <el-button type="primary" size="mini" icon="el-icon-upload">
            <transition name="el-fade-in-linear">
              <span v-show="!isMinScreen">{{msg('更改订单状态')}}</span>
            </transition>
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="cancel" v-if="hasPermission('express:cancel')">{{msg('取消订单')}}</el-dropdown-item>
            <el-dropdown-item command="time-out" v-if="hasPermission('express:timeOut')">{{msg('面单过期')}}</el-dropdown-item>
            <el-dropdown-item command="create" v-if="hasPermission('express:updateCreated')">{{msg('线下已建单')}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-button type="primary" size="mini" @click="showTotal">总数
        </el-button>
      </el-button-group>
    </template>
    <template slot="tools">
      <el-button-group>
        <el-button type="success" @click="getExps(true)" size="mini">
          <i class="el-icon-search" style="display:inline-block;margin-right:4px; "></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('搜索')}}</span>
          </transition>
        </el-button>
        <el-button type="success" @click="queryVisiable = true;" size="mini">
          <i class="el-icon-search" style="display:inline-block;margin-right:4px; "></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('批量查询')}}</span>
          </transition>
        </el-button>
        <el-button type="info" size="mini" @click="expendQuery()">
          <i class="el-icon-arrow-down" v-if="!isQueryExpend" style="display:inline-block;margin-right:4px; "></i>
          <i class="el-icon-arrow-up"  v-else style="display:inline-block;margin-right:4px; "></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('高级')}}</span>
          </transition>
        </el-button>
      </el-button-group>
      <el-dropdown  @command="clickDownloadItem" v-if="hasPermissions(['express:download', 'express:downloadExp'])">
          <el-button type="primary" size="mini" icon="el-icon-upload">
            <transition name="el-fade-in-linear">
              <span v-show="!isMinScreen">{{msg('导出')}}</span>
            </transition>
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="downloadDefau" v-if="hasPermission('express:download')">{{msg('导出当前页')}}</el-dropdown-item>
            <el-dropdown-item command="downloadNew" v-if="hasPermission('express:download')">{{msg('导出含新字段')}}</el-dropdown-item>
            <el-dropdown-item command="downloadExp" v-if="hasPermission('express:downloadExp')">{{msg('下载建单资料')}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
    </template>
  </vxe-toolbar>
  <div class="table-wrap">

    <vxe-table style="width: 100%" :height="docHeight" ref="dataTable"
      border="inner" size="small"  stripe resizable  :data="exps" 
      show-overflow 
      highlight-hover-row highlight-current-row highlight-hover-column highlight-current-column
      :expand-config="{iconOpen: 'el-icon-caret-bottom', iconClose: 'el-icon-caret-right', toggleMethod: toggleExpandMethod}"
    >
      <vxe-table-column type="checkbox" width="40" :fixed="isMinScreen?null: 'left'"></vxe-table-column>
      <vxe-table-column  type="expand" :title="msg('ID')"  min-width="90" :fixed="isMinScreen ? null : 'left'" 
      >
        <template v-slot="{ row }">
            <div style="display:inline-block;" width="100">
              <span>{{ row.id }}</span>
            </div>
        </template>
        <template v-slot:content="{ row }">
          <div>
            <div class="expand-box">
              <p style="font-weight:bold;">物流信息</p>
              <div class="info">
                <span class="label">{{msg('ID')}}:<span class="txt">{{row.id}}</span></span>
                <span class="label">{{msg('订单编号')}}:<span class="txt">{{row.orderCode}}</span></span>
                <span class="label">{{msg('物流厂商')}}:<span class="txt">{{row.expCompany}}</span></span>
                <span class="label">{{msg('品名')}}:<span class="txt">{{row.goodName}}</span></span>
                <span class="label">{{msg('面单码')}}:<span class="txt">{{row.expPageCode}}</span></span>
                <span class="label">{{msg('台湾物流码')}}:<span class="txt">{{row.twExpCode}}</span></span>
                <span class="label">{{msg('是否可以上传面')}}:<span class="txt">{{dicts.allowUploadPages[row.allowUploadPage]}}</span></span>
                <span class="label">{{msg('物流状态')}}:<span class="txt">{{dicts.expStatus[row.status]}}</span></span>
                <span class="label">{{msg('面单状态')}}:<span class="txt">{{dicts.expPageStatus[row.pagePrintStatus]}}</span></span>
                <span class="label">{{msg('配送状态')}}:<span class="txt">{{row.orderStatus}}</span></span>
                <span class="label">{{msg('重量')}}:<span class="txt">{{row.weight}}</span></span>
                <span class="label">{{msg('店铺名')}}:<span class="txt">{{row.shopOwner}}</span></span>
                <!-- <span class="label">{{msg('店铺名ID')}}:<span class="txt">{{row.shopId}}</span></span> -->
                <span class="label">{{msg('来源平台')}}:<span class="txt">{{dicts.expPlatforms[row.platform]}}</span></span>
                <span class="label">{{msg('订单来源')}}:<span class="txt">{{dicts.expOrigins[row.pageType]}}</span></span>
                <!-- <span class="label">{{msg('打包类型')}}:<span class="txt">{{dicts.expPkgTypes[row.hasStorePkg]}}</span></span> -->
                <span class="label">{{msg('收件人')}}:<span class="txt">{{row.receiver}}</span></span>
                <span class="label">{{msg('电话')}}:<span class="txt">{{row.receiverTel}}</span></span>
                <span class="label">{{msg('门店号区码')}}:<span class="txt">{{row.shopCode}}</span></span>
                <span class="label">{{msg('创建人')}}:<span class="txt">{{row.createrName}}</span></span>
                <span class="label">{{msg('创建时间')}}:<span class="txt">{{row.created}}</span></span>
                <span class="label">{{msg('上传面单时间')}}:<span class="txt">{{row.uploadDate}}</span></span>
                <span class="label">{{msg('打印面单时间')}}:<span class="txt">{{row.printDate}}</span></span>
                <!-- <span class="label">{{msg('通知上传面单的时间')}}:<span class="txt">{{row.allowUploadPageDate}}</span></span> -->
                <span class="label">{{msg('备注')}}:<span class="txt">{{row.remark}}</span></span>
                <!-- <span class="label">{{msg('报单备注1')}}:<span class="txt">{{row.remark1}}</span></span> -->
                <!-- <span class="label">{{msg('报单备注2')}}:<span class="txt">{{row.remark2}}</span></span> -->
                <span class="label"  style="width: 500px;">{{msg('地址')}}:<span class="txt" style="display:inline-block; word-break: break-all;">{{row.receiverAddr}}</span></span>
              </div>

              <p v-if="row.expPkg" style="font-weight:bold;">装袋信息</p>
              <div v-if="row.expPkg" class="info">
                <span class="label">{{msg('ID')}}:<span class="txt">{{row.expPkg.id}}</span></span>
                <span class="label">{{msg('袋号')}}:<span class="txt">{{row.expPkg.pkgCode}}</span></span>
                <span class="label">{{msg('装船时间')}}:<span class="txt">{{row.expPkg.zhuangchuanDate}}</span></span>
                <span class="label">{{msg('清关时间')}}:<span class="txt">{{row.expPkg.qingguanDate}}</span></span>
                <span class="label">{{msg('提货时间')}}:<span class="txt">{{row.expPkg.pickUpDate}}</span></span>
                <span class="label">{{msg('发货时间')}}:<span class="txt">{{row.expPkg.sendDate}}</span></span>
                <span class="label">{{msg('创建时间')}}:<span class="txt">{{row.expPkg.created}}</span></span>
                <span class="label">{{msg('打包时间')}}:<span class="txt">{{row.expPkg.packaged}}</span></span>
                <span class="label">{{msg('重量')}}:<span class="txt">{{row.expPkg.weight}}</span></span>
                <span class="label">{{msg('状态')}}:<span class="txt">{{dicts.expPkgStatus[row.expPkg.status]}}</span></span>
              </div>

              <p v-if="row.payment" style="font-weight:bold;">物流金额信息</p>
              <div v-if="row.payment" class="info">
                <span class="label">{{msg('订单手续费')}}:<span class="txt">{{row.payment.orderCharge}}</span></span>
                <span class="label">{{msg('已收款台币')}}:<span class="txt">{{row.payment.receivedMoney}}</span></span>
                <span class="label">{{msg('代收货款')}}:<span class="txt">{{row.payment.collecPayment}}</span></span>
                <span class="label">{{msg('淘宝价人民币')}}:<span class="txt">{{row.payment.taobaoPrice}}</span></span>
                <span class="label" style="width: 400px;">{{msg('国内平台订单编号')}}:<span class="txt">{{row.payment.innerOrderCode}}</span></span>
                <span class="label" style="width: 500px;">{{msg('淘宝购买链接')}}:<span class="txt" style="display:inline-block; word-break: break-all;">{{row.payment.taobaoUrl}}</span></span>
              </div>

              <p v-if="row.store" style="font-weight:bold;">台湾上架信息</p>
              <div v-if="row.store" class="info">
                <span class="label">{{msg('货架号')}}:<span class="txt">{{row.store.shelfCode}}</span></span>
                <span class="label">{{msg('上架时间')}}:<span class="txt">{{row.store.created}}</span></span>
                <span class="label">{{msg('仓库状态')}}:<span class="txt">{{dicts.expBackStatus[row.store.status]}}</span></span>
                <span class="label">{{msg('回寄单号')}}:<span class="txt">{{row.store.backCode}}</span></span>
                <span class="label">{{msg('保管过期时间')}}:<span class="txt">{{row.store.saveExpire}}</span></span>
                <span class="label">{{msg('上架码')}}:<span class="txt">{{row.store.code}}</span></span>
              </div>

              <p v-if="row.orders && row.orders.length>0" style="font-weight:bold;">物流详情</p>
              <table v-if="row.orders && row.orders.length>0" class="order-table" cellspacing="0" cellpadding="0">
                <thead>
                  <tr>
                    <th>{{msg('商品名')}}</th>
                    <th>{{msg('价格')}}</th>
                    <th>{{msg('规格')}}</th>
                    <th>{{msg('件数')}}</th>
                    <th>{{msg('仓库货架号')}}</th>
                    <th>{{msg('国内物流快递单号')}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="order in row.orders" :key="order.id">
                    <td>{{order.name}}</td>
                    <td>{{order.price}}</td>
                    <td>{{order.spec}}</td>
                    <td>{{order.qty}}</td>
                    <td>{{order.storeShelfCode}}</td>
                    <td>{{order.innerExpCode}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </template>
      </vxe-table-column>
      <vxe-table-column field="created" :title="msg('建单时间')" min-width="120"
         sortable :filters="[{data: []}]" :filter-render="{name: 'ElDatePicker', props: {type: 'daterange', rangeSeparator: '至', startPlaceholder: '开始日期', endPlaceholder: '结束日期'}}"
      >
      </vxe-table-column>
      <vxe-table-column field="orderCode" :title="msg('物流信息编号')" min-width="150" 
        sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}"
      >
      </vxe-table-column>
      <vxe-table-column field="status" :title="msg('物流状态')" min-width="130" 
        sortable :filters="dicts.expStatus1"
      >
        <template v-slot="{ row }">
          <el-tag :type="dicts.cssTypes[row.status]" disable-transitions>
            {{dicts.expStatus[row.status]}}
          </el-tag>
        </template>
      </vxe-table-column>
      <vxe-table-column field="pagePrintStatus" :title="msg('面单打印状态')" min-width="150" 
        sortable :filters="dicts.expPageStatus1"
      >
        <template v-slot="{ row }">
          <el-link :type="dicts.cssTypes[row.pagePrintStatus]" @click="openPdfPage(row.pagePrintStatus, row.expPageId)">
            {{dicts.expPageStatus[row.pagePrintStatus]}}
          </el-link>
        </template>
      </vxe-table-column>
      <vxe-table-column field="remark4" :title="msg('发货失败信息')" min-width="120" 
        sortable
      ></vxe-table-column>
      <vxe-table-column field="expCompany" :title="msg('物流厂商')" min-width="120" 
        sortable :filters="dicts.expCompanies"
      ></vxe-table-column>
      <vxe-table-column field="pkgCode" :title="msg('袋号')" min-width="150" 
        sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}"
      ></vxe-table-column>
      <!-- <vxe-table-column field="remark1" :title="msg('报单备注1')" width="150" :show-overflow="true" 
        sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}"
      >
      </vxe-table-column> -->
      <!-- <vxe-table-column field="shopId" :title="msg('店铺ID')" width="150" :show-overflow="true" 
        sortable :filters="[{data: {vals: [], sVal: ''}}]" :filter-render="{name: 'FilterContent'}"
      >
      </vxe-table-column> -->
      <vxe-table-column field="shopOwner" :title="msg('店铺')" min-width="120" 
        sortable :filters="[{data: {vals: [], sVal: ''}}]" :filter-render="{name: 'FilterContent'}"
      >
        <template v-slot="{ row }">
           {{(row.shopId? ("("+row.shopId+")"):"") + row.shopOwner?row.shopOwner:""}}
        </template>
      </vxe-table-column>
      <vxe-table-column field="remark" :title="msg('虾皮备注')" width="150" :show-overflow="true" 
        sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}"
      >
        <template v-slot="{ row }">
          <el-tooltip class="item" effect="dark" :content="row.remark">
             <span>{{row.remark}}</span>
          </el-tooltip>
        </template>
      </vxe-table-column>
      <!-- <vxe-table-column field="allowUploadPage" :title="msg('允许上传面单')" min-width="140" 
        sortable :filters="dicts.allowUploadPages1" :formatter="formatterSex"
      >
        <template v-slot="{ row }">
          <el-tag :type="dicts.cssTypes[row.allowUploadPage]" disable-transitions>
            {{dicts.allowUploadPages[row.allowUploadPage]}}
          </el-tag>
        </template>
      </vxe-table-column> -->
      <vxe-table-column field="platform" :title="msg('来源')" min-width="120" 
        sortable :filters="dicts.expPlatforms1"
      >
        <template v-slot="{ row }">
          <el-tag type="warning" disable-transitions>{{dicts.expPlatforms[row.platform]}}</el-tag>
        </template>
      </vxe-table-column>
      <vxe-table-column field="twExpCode" :title="msg('台湾物流码')" min-width="130" 
        sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}"
      ></vxe-table-column>
      <vxe-table-column field="expPageCode" :title="msg('面单码')" min-width="150" 
        sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}"
      ></vxe-table-column>
      <vxe-table-column field="receiver" :title="msg('收件人')" min-width="100" 
        sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}"
      ></vxe-table-column>
      <vxe-table-column field="receiverTel" :title="msg('收件人电话')" min-width="130" 
        sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}"
      ></vxe-table-column>
      <vxe-table-column field="receiverAddr" :title="msg('收件人地址')" width="150" :show-overflow-tip="true"
        sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}"
      >
        <template v-slot="{ row }">
          <el-tooltip class="item" effect="dark" :content="row.receiverAddr">
             <span>{{row.receiverAddr}}</span>
          </el-tooltip>
        </template>
      </vxe-table-column>
      <!-- <vxe-table-column field="shopCode" :title="msg('门店号区码')" min-width="130" 
        sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}"
      ></vxe-table-column> -->
      <vxe-table-column field="weight" :title="msg('票重')" width="100" 
        sortable :filters="[{data: {type: 'has', isCase: false, name: ''}}]" :filter-render="{name: 'FilterComplex'}"
      >
        <template v-slot="{ row }">
             <span v-if="row.weight==-1">{{msg("未到货")}}</span>
             <span v-else>{{row.weight}}</span>
        </template>
      </vxe-table-column>
      <vxe-table-column field="totalWeight" :title="msg('包重')" width="100" 
        sortable :filters="[{data: {type: 'has', isCase: false, name: ''}}]" :filter-render="{name: 'FilterComplex'}"
      >
      </vxe-table-column>
      <vxe-table-column field="goodName" :title="msg('品名')" width="150" :show-overflow="true" 
        sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}"
      >
        <template v-slot="{ row }">
          <el-tooltip class="item" effect="dark" :content="row.goodName">
             <span>{{row.goodName}}</span>
          </el-tooltip>
        </template>
      </vxe-table-column>
      <vxe-table-column field="spec" :title="msg('规格')" min-width="100" 
        sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}"
      ></vxe-table-column>
      <vxe-table-column field="hasStorePkg" :title="msg('打包类型')" min-width="150" 
        sortable prop="hasStorePkg" :filters="dicts.expPkgTypes1" 
      >
        <template v-slot="{ row }">
          <el-tag :type="dicts.cssTypes[row.hasStorePkg]" disable-transitions>
            {{dicts.expPkgTypes[row.hasStorePkg]}}
          </el-tag>
        </template>
      </vxe-table-column>
      <vxe-table-column field="pageType" :title="msg('订单来源')" min-width="130" 
        sortable :filters="dicts.expOrigins1"
      >
        <template v-slot="{ row }">
          <span>
            <el-tag :type="dicts.cssTypes[row.pageType]" disable-transitions>
              {{dicts.expOrigins[row.pageType]}}
            </el-tag>
          </span>
        </template>
      </vxe-table-column>
      <vxe-table-column field="createrName" :title="msg('创建人')" min-width="100" 
        sortable :filters="[{data: {vals: [], sVal: ''}}]" :filter-render="{name: 'FilterContent'}"
      ></vxe-table-column>
      <!-- <vxe-table-column field="username" :title="msg('创建账号')" min-width="100" 
        sortable :filters="[{data: {vals: [], sVal: ''}}]" :filter-render="{name: 'FilterContent'}"
      ></vxe-table-column> -->
      <!-- <vxe-table-column field="allowUploadPageDate" :title="msg('通知上传面单时间')" min-width="170"
         sortable :filters="[{data: []}]" :filter-render="{name: 'ElDatePicker', props: {type: 'daterange', rangeSeparator: '至', startPlaceholder: '开始日期', endPlaceholder: '结束日期'}}"
      >
      </vxe-table-column> -->
      <vxe-table-column field="uploadDate" :title="msg('上传面单时间')" min-width="150"
         sortable :filters="[{data: []}]" :filter-render="{name: 'ElDatePicker', props: {type: 'daterange', rangeSeparator: '至', startPlaceholder: '开始日期', endPlaceholder: '结束日期'}}"
      ></vxe-table-column>
      <vxe-table-column field="printDate" :title="msg('打印面单时间')" min-width="150"
         sortable :filters="[{data: []}]" :filter-render="{name: 'ElDatePicker', props: {type: 'daterange', rangeSeparator: '至', startPlaceholder: '开始日期', endPlaceholder: '结束日期'}}"
      ></vxe-table-column>
      <vxe-table-column field="printTicketDate" :title="msg('打印货单时间')" min-width="150"
         sortable :filters="[{data: []}]" :filter-render="{name: 'ElDatePicker', props: {type: 'daterange', rangeSeparator: '至', startPlaceholder: '开始日期', endPlaceholder: '结束日期'}}"
      ></vxe-table-column>
      <!-- <vxe-table-column field="pickUpDate" :title="msg('提货时间')" min-width="150"
         sortable :filters="[{data: []}]" :filter-render="{name: 'ElDatePicker', props: {type: 'daterange', rangeSeparator: '至', startPlaceholder: '开始日期', endPlaceholder: '结束日期'}}"
      ></vxe-table-column> -->
      <!-- <vxe-table-column field="remark3" :title="msg('托运状态')" width="150" :show-overflow="true" 
        sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}"
      >
      </vxe-table-column> -->
      <!-- <vxe-table-column field="orderStatus" :title="msg('配送状态')" min-width="130" 
        sortable :filters="[{data: {vals: [], sVal: ''}}]" :filter-render="{name: 'FilterContent'}"
      >
      </vxe-table-column> -->
      <!-- <vxe-table-column field="remark2" :title="msg('报单备注2')" width="150" :show-overflow="true" 
        sortable :filters="[{data: null}]" :filter-render="{name: 'FilterInput'}"
      >
      </vxe-table-column>             -->
    </vxe-table>
    <el-pagination id="xpagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :background="true"
      :current-page.sync="currentPage"
      :page-sizes="[10,20 ,50, 100, 200, 500, 1000,2000, 5000,10000,20000,50000]"
      :page-size="query.length"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>

  <el-dialog :title="dglogTitle" :visible.sync="dialogTableVisible" :fullscreen="isMinScreen" width="800px" @opened="hendleOpenedEdit()">
    <exp-edit :id="currId" :exp="currExp" v-on:close-dg="closeEditDialog" ref="expEdit" :has-view="false"/>
  </el-dialog>

  <el-dialog :title="msg('添加线下建单资料')" :visible.sync="dialogUnderLineVisible" :fullscreen="isMinScreen" width="800px">
    <exp-add :exp="underLineExp" v-on:closeDg="closeUnderLineDialog" ref="expUnderLine" :has-view="false"/>
  </el-dialog>

  <el-dialog :title="msg('批量导入面单')" :visible.sync="dialogImportVisible" :fullscreen="isMinScreen"  width="600px" @opened="hendleOpenedImport()" >
    <exp-page-import ref="expPageImport"/>
  </el-dialog> 

  <el-dialog :title="msg('单独导入面单')" :visible.sync="dialogOnlyImportVisible" :fullscreen="isMinScreen"  width="600px" @opened="hendleOpenedOnlyImport()" >
    <exp-only-page-import ref="expOnlyPageImport"/>
  </el-dialog>
 
 
  <el-dialog :title="msg('批量导入更新')" :visible.sync="weightImportVisible" :fullscreen="isMinScreen"  width="600px" @opened="weightImport()" >
    <exp-import-update ref="pkgWeightImport"/>
  </el-dialog> 

  <el-dialog :title="msg('批量更新')" :visible.sync="updateFieldVisiable" :fullscreen="isMinScreen"  width="600px">
    <update-field ref="updateFiledRef" :datas="fields" @closeDg="updateFieldVisiable=false"/>
  </el-dialog> 
  
  <el-dialog :title="msg('批量查询')" :visible.sync="queryVisiable" :fullscreen="isMinScreen"  width="450px" >
    <express-query ref="queryForm" @closeDg="doBatchQuery"/>
  </el-dialog> 


   <el-image-viewer v-if="showViewer" :on-close="()=>{this.showViewer = false}" :url-list="[imgUrl]" />

</div>
</template> 

<script>
// @ is an alias to /src
import ExpEdit from "./ExpEdit.vue";
import ExpAddUnderLine from "./ExpAddUnderLine.vue";
import ExpPageImport from "./ExpPageImport.vue";
import ExpOnlyPageImport from "./ExpOnlyPageImport.vue";
import ExpImportUpdate from "./ExpImportUpdate.vue";
import UpdateField from "./ExpBatchUpdateField.vue";
import ExpressQuery from "./ExpressQuery.vue";
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';

export default {
  name: 'Express',
  components: {
    ExpEdit: ExpEdit,
    ExpAdd:ExpAddUnderLine,
    ExpPageImport: ExpPageImport,
    ExpImportUpdate:ExpImportUpdate,
    ElImageViewer: ElImageViewer,
    ExpressQuery: ExpressQuery,
    UpdateField: UpdateField,
    ExpOnlyPageImport:ExpOnlyPageImport,
  },
  mounted:function(){
    var _this = this;

    var user = JSON.parse(window.localStorage.getItem("user"));
    this.userName = user.username;
    this.roleName = user.role.name;

    let name = user.role.name;

    if(name.indexOf('search') > -1){
      console.log('不显示列表');
    }else{
      this.getExps();
    }
  },
  data(){
    return{
        query:{  //查询条件
          start: 0,
          length: 10,
          expCode: null,
          pkgCode: null,
          receiver: null,
          expCompany: null,                                         
          status: null,
          expPageCode: null,
          twExpCode: null,
          createrName: null,
          pagePrintStatus: null,
          hasWeight: null,
          Date: [], 
          allowUploadPage:null,
          hasStorePkg:null,
          packaged: null,
          pageType:null,
          remark1:null,
          pickUpDate:[],
          printDate:[],
          printTicketDate:[],
          shopId:null,
        },
        pdfUrl: this.$kit.api.pdf+"?id=",
        statuses: this.$kit.expStatuses,

        currentPage: 1,   //分页的当前页
        exps: [],   //表格数据列表
        dglogTitle: this.msg('编辑物流信息'),
        isQueryExpend: this.$store.state.isQueryExpend,  //查询框是否展开
        total: 0,//总记录数,
        currId: null,   //当前列表中选中的id
        dialogTableVisible: false, //编辑详情的隐藏与显示,
        columns: {},  //字段是否显示

        dialogImportVisible: false,
        dialogOnlyImportVisible:false,
        weightImportVisible:false,
        updateFieldVisiable: false,
        showViewer:false,
        imgUrl: null,
        dialogBackEditVisible:false,
        dialogUnderLineVisible:false,

        currExp:null,
        underLineExp:null,
        userName:null,
        expBacks:[],

        fields: [],

        queryVisiable: false, //查询弹窗

        queryType:null,
        queryCodes:null,

        roleName:null,
    } 
  }, 
  computed: {
    isMinScreen: {  //是否小屏幕，浏览器
        get() {
          return this.$store.state.isMinScreen;
        }
    },
    docHeight: {  //表格的高度
        get() {
          var height =  this.$store.state.docHeight;
          return height - 320;
        }
    },
    isEdit: {
          get(){
                return this.id==null;
          }
      }
  },
  methods: {
    formatterSex ({ cellValue }) {
      return this.dicts.allowUploadPages[cellValue];
    },
    getExps: function(start){ //获取列表
      var _this = this;
      var param = this.$kit.rebuildQuery(this.query);
      if(start){
        param.start = 0;
        this.currentPage = 1;
        this.queryType = null;
        this.queryCodes = null;
      }

      this.axios.post(this.$kit.api.expList, param).then( (result) => {
        if(result.status){
          if(_this.exps.length>0)
            _this.exps.splice(0);
          
          _this.total = result.data.recordsTotal;
          var expDatas = result.data.data;

          // this.query['type'] = null;
          // this.query['codes'] = null;

          for(var i=0; i<expDatas.length; i++){
            var item = expDatas[i];
            // if(item.weight == -1){
            //   item.weight = this.msg('未到货');
            // }

          } 
          _this.exps = expDatas; 
          
        }else{
          _this.$message(result.msg);
        }
        
      }).catch(function (error) {
        console.error(error);
      });

    },//end function
    refresh: function(){  //刷新列表
      this.$kit.clearQuery(this.query);
      this.currentPage = 1;
      this.getExps();
    },
    expendQuery: function(){  //展开与收缩查询框
        this.isQueryExpend = !this.isQueryExpend;
    },
    handleSizeChange(val) {  //分页的每页数据变化后的回调事件
      //console.log(`每页 ${val} 条`);
      this.query.length = val;
      this.query.start = 0;
      this.getExps();
    },
    handleCurrentChange(val) {   //分页的当前页改变后的回调事件
      //console.log(`当前页: ${val}`);
      this.query.start = this.query.length * (val -1);
      this.getExps();
    },
    //批量查询
    doBatchQuery(data){
      this.queryVisiable = false;
      if(data){
        this.query['type'] = data.type;
        this.query['codes'] = data.codes;
        this.queryType = data.type;
        this.queryCodes = data.codes;
        this.getExps();
      }else{
        this.queryType = null;
        this.queryCodes = null;
      }
    },

    add(){  //添加
      this.currId = null;
      this.currExp = {
        orderCode: null,
        expCompany: null,
        receiver:null,
        receiverTel: null, 
        receiverAddr: null,
        goodName: null,
        expPageId: null,
        status: null,
        expPageCode: null,
        pagePrintStatus: null,
        orders:[],
        expPkg:{
          pkgCode: null,
        },
        expPages:[],
      };
      this.dglogTitle = this.msg("添加新物流信息");
      
      // var expEdit = this.$refs.expEdit;
      // if(expEdit){
      //   expEdit.initForm(null);
      // }
      this.hasEdit = false;
      this.dialogTableVisible = true;
    },
    addUnderline(){
      this.underLineExp = {
        orderCode:null,
        receiver:null,
        receiverTel: null, 
        receiverAddr: null,
        shopCode:null,
        goodName: null,
        amount:null,
        expCompany:null,
        status: 0,
        pagePrintStatus: 0,
        allowUploadPage:0,
        hasStorePkg:1,
        pageType:2,
        orders:[
          {
            name: null,
            spec: null,
            qty: 1,
            price:0,
            shopOwner: null,
            remark: null
        }
        ],
      };
      this.dialogUnderLineVisible = true;
    },
    edit(){  //修改
      var _this = this;
      var selectes = this.$refs.dataTable.getCheckboxRecords();
      if(selectes.length==0){
        this.$message(this.msg('请选中一条数据进行操作'));
        return;
      }
      if(selectes.length>1){
        this.$message(this.msg('只能操作一条数据'));
        return;
      }

      //如果物流状态为已发货，则不允许修改任何信息
      if(this.userName != "admin"){
        if(selectes[0].status == 2){
            this.$message(this.msg('该订单已发货，不允许修改'));
            return;
        }
      }

      this.axios.get(this.$kit.api.expDetail+"?id="+selectes[0].id).then((result) => {
        if(result.status){
            _this.currExp = result.data;
            _this.currId = _this.currExp.id;
            _this.expPages = []; 

            if(!_this.currExp.expPkg){
              _this.currExp.expPkg = {pkgCode: null};
            }

            if(_this.currExp.expPageId){
              _this.expPages.push(_this.$kit.api.imgView+"?id="+_this.currExp.expPageId);
            }
            _this.hasEdit = true;
            _this.dglogTitle = this.msg("编辑物流信息");
            _this.dialogTableVisible = true;
        }else{
          _this.$message(result.msg);
        }
        
      }).catch(function (error) {
          console.error(error);
      });
    },
    closeEditDialog(type){  //编辑弹窗关闭后的回调事件
      if(type){
        this.getExps();
      }
      this.dialogTableVisible = false;
    },
    closeUnderLineDialog(type){
      if(type){
        this.getExps();
      }
      this.dialogUnderLineVisible = false;
    },
    closeBackEditDialog(type){  //编辑弹窗关闭后的回调事件
      if(type){
        this.getExps();
      }
      this.expBacks = [],
      this.dialogBackEditVisible = false;
    },
    deleted(){  //删除
      var selectes = this.$refs.dataTable.getCheckboxRecords();
      if(selectes.length==0){
        this.$message(this.msg('请选中一条数据进行操作'));
        return;
      }

      var ids = selectes.map( o => o.id);
      
      var _this = this;

      this.$confirm(this.msg('确定要删除该物流信息吗?'), this.msg('提示'), {
          confirmButtonText: this.msg('确定'),
          cancelButtonText: this.msg('取消'),
          type: 'warning'
        }).then(() => {
          _this.$axios.post(_this.$kit.api.expDelete, ids)
            .then(function(result){
              _this.$message(result.msg || _this.msg('删除成功'));
              _this.getExps();
          });
        });
    },
    importPage(){
      this.dialogImportVisible = true;
    },
    onlyImportPage(){
      this.dialogOnlyImportVisible = true;
    },
    hendleOpenedImport(){
      this.$nextTick(() => {
        this.$refs.expPageImport.initForm();
      });
      
    },
    hendleOpenedEdit(){
      this.$nextTick(() => {
        this.$refs.expEdit.init();
      });
    },
    hendleOpenedOnlyImport(){
      this.$nextTick(() => {
        this.$refs.expOnlyPageImport.initForm();
      });
      
    },
    weightImport(){
        this.$nextTick(() => {
        this.$refs.pkgWeightImport.initForm();
      });
    },
    openPdfPage(status, expPageId){
      if(status==0) return;
      this.imgUrl = this.pdfUrl+expPageId;
      this.showViewer = true;
    },
    selectedColumn(cols){
      this.columns = cols;
      console.log(this.columns);
    },
    uploadWeight(){
      var selectes = this.$refs.dataTable.getCheckboxRecords();
      if(!selectes || selectes.length == 0){
        this.weightImportVisible = true;
      }else{
        this.fields = selectes.map( e => { return {id: e.id, key: e.orderCode, value: null}});
        this.updateFieldVisiable = true;
      }

    },
   notifyUpPage(status){//通知上传面单
      var _this = this;
      var selectes = this.$refs.dataTable.getCheckboxRecords();
      if(selectes.length==0){
        this.$message(this.msg('请选中一条数据进行操作'));
        return;
      }

      var ids = selectes.map(e => e.id);
      this.$confirm(this.msg('确定通知上传面单吗?'), this.msg('提示'), {
          confirmButtonText: this.msg('确定'),
          cancelButtonText: this.msg('取消'),
          type: 'warning'
        }).then(() => {
          this.axios.get(this.$kit.api.expUpdatePageStatus+"?ids="+ids.join(",")+"&s="+status).then((result) => {
            _this.$message(result.msg);
            if(result.status){
              _this.getExps();
            }
          }).catch(function (error) {
            console.error(error);
          });
        });

    },
    //取消订单
    expCancel(selectes){
      // var selectes = this.$refs.dataTable.getCheckboxRecords();

      if(selectes.length==0){
        this.$message(this.msg('请选中一条数据进行操作'));
        return;
      }

      for(var i=0;i<selectes.length;i++){
        if(selectes[i] && selectes[i].status){
            if(selectes[i].status == 9){
                this.$message(this.msg('面单过期的订单不能取消'));
                return;
            }
        }
      }

      var ids = selectes.map(s => s.id);

      this.axios.get(this.$kit.api.expCancel+"?ids="+ids).then((result) => {
        if(result.status){
            this.getExps(); 
        }
        this.$message(result.msg);
      }).catch(function (error) {
          console.error(error);
      });
    },
    clickProcessItem(command){ 

      var selectes = this.$refs.dataTable.getCheckboxRecords();
      if(selectes.length==0){
        this.$message(this.msg('请选中一条数据进行操作'));
        return;
      }

      if(command=='cancel'){
          this.expCancel(selectes);
      }else if(command == 'time-out'){
          this.expTimeOut(selectes);
      }else if(command == 'create'){
          this.updateCreate(selectes);
      }

    },
    updateCreate(selectes){ //将线下的更新为线下已建单

      var ids = selectes.map(s => s.id);
      this.axios.get(this.$kit.api.expUpdateCreated+"?ids="+ids).then((result) => {
        if(result.status){
            this.getExps(); 
        }
        this.$message(result.msg);
      }).catch(function (error) {
          console.error(error);
      });
    },

    expTimeOut(selectes){

      var ids = selectes.map(s => s.id);
      this.axios.get(this.$kit.api.expTimeOut+"?ids="+ids).then((result) => {
        if(result.status){
            this.getExps(); 
        }
        this.$message(result.msg);
      }).catch(function (error) {
          console.error(error);
      });
    },
    clickPageItem(command){
      if(command=='page-upload'){
          this.importPage();
      }else if(command == 'only-page-upload'){
          this.onlyImportPage();
      }
    },
    autoResend(){
      var selectes = this.$refs.dataTable.getCheckboxRecords();
      if(selectes.length==0){
        this.$message(this.msg('请选中一条数据进行操作'));
        return;
      }

      this.axios.get(this.$kit.api.resend.autoResend+"?ids="+selectes.map(e => e.id).join(",")).then((result) => {
        if(result.status){ 
            this.getExps(); 
        }
        this.$message(result.msg);
      }).catch(function (error) {
          console.error(error);
      });
    },
    clickDownloadItem(command){
      if(command=='downloadDefau'){
          var datas = this.$refs.dataTable.getTableData();
          this.$refs.dataTable.exportData({data: datas.visibleData,
            type: 'xlsx',filename: this.msg('物流信息下载'),sheetName: 'Sheet1' 
          })
      }else if(command == 'downloadNew'){
          this.download();
      }else if(command == 'downloadExp'){
          this.downloadExp();
      }
    },
    //下载建单资料
    downloadExp(){
      var _this = this;
      this.query['type'] = this.queryType;
      this.query['codes'] = this.queryCodes;
      var param = this.$kit.rebuildQuery(this.query);
      window.location.href= this.$kit.api.exportExp+"?data="+encodeURIComponent(JSON.stringify(param));
    },
	download(){
      var _this = this;
      this.query['type'] = this.queryType;
      this.query['codes'] = this.queryCodes;
      var param = this.$kit.rebuildQuery(this.query);
      window.location.href= this.$kit.api.downloadExpress+"?data="+encodeURIComponent(JSON.stringify(param));
    },toggleExpandMethod({ expanded, row }){
      if(expanded){
        if(!row["isUpdated"]){
           this.axios.get(this.$kit.api.expDetail+"?id="+row.id).then((result) => {
            if(result.status){
              row["isUpdated"] = true;
              Object.assign(row, result.data);
            }
          }).catch(function (error) {
              console.error(error);
          });
        }
      }
      return true;
    },
    batchSend(){
      var selectes = this.$refs.dataTable.getCheckboxRecords();
      if(selectes.length==0){
        this.$message(this.msg('请选中一条数据进行操作'));
        return;
      }

      // var err = [];
      // for(var i=0;i<selectes.length;i++){
      //   let item = selectes[i];
      //   if(item.allowUploadPage == 0){
      //     err.push(item);
      //   }
      // }

      // if(err.length > 0){
      //   this.$message({
      //     message: this.msg('面单列印状态为‘不可以’的数据不能执行发货操作!!!请重新选择'),
      //     type: 'error'
      //   });
      //   return;
      // }

      var _this = this;
      this.$confirm(_this.msg('确定要一键发货吗?'), _this.msg('提示'), {
          confirmButtonText: _this.msg('确定'),
          cancelButtonText: _this.msg('取消'),
          type: 'warning'
        }).then(() => {
          _this.axios.get(_this.$kit.api.batchSend+"?ids="+selectes.map(e => e.id).join(",")).then((result) => {
            if(result.status){ 
                _this.getExps(); 
            }
            _this.$message(result.data);
          }).catch(function (error) {
              console.error(error);
          });
        });
    },
    showTotal(){
      var {visibleData} =  this.$refs.dataTable.getTableData();
      this.$message.error('当前显示：'+visibleData.length+"条");
    },
  }
}
</script>

<style class="scss" scoped>
.expand-box{margin-left:150px;}
.info{width:900px; display:flex; flex-wrap: wrap;}
.info .label{width:200px; margin-left:5px; } 
.info .label .txt{color: black; margin-left:5px;}
.order-table{border-top: 1px solid #aaa; border-left: 1px solid #aaa;}
.order-table td, .order-table th{border-bottom: 1px solid #aaa; border-right:1px solid #aaa; padding: 3px 5px;}
.expand-box p{margin-top:15px; margin-bottom: 5px; padding:0px;}
</style>
