<template>
<div class="content-panel">
  <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
    <el-col :span="20">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">{{msg('首页')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{msg('臨期訂單')}}</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>
    <el-col :span="4" style="text-align:right;">
      <el-button type="success" size="small" icon="el-icon-refresh" @click="refresh()" circle></el-button>
    </el-col>
  </el-row>

  <el-row :gutter="10"  class="query-wrap" :style="{height: isQueryExpend?'auto':'120px'}">
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  :placeholder="msg('单号')" v-model="query.expCode" size="small">
          <i slot="prefix" class="el-input__icon el-icon-tickets"></i>
        </el-input>
    </el-col>
    <!-- <el-col :xs="24" :sm="8" :lg="6" :xl="4">
       <el-date-picker  v-model="query.Date"  type="daterange"  :range-separator="msg('至')" 
            :start-placeholder="msg('操作时间')"  value-format="yyyy-MM-dd"
            end-placeholder=""
            size="small"
          >
        </el-date-picker>
    </el-col> -->
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input type="textarea" :rows="4" :placeholder="msg('批量查询')" v-model="query.codeStrs" size="small">
        </el-input>
    </el-col>
  </el-row>

  <vxe-toolbar id="toolbar15" :custom="{storage: true}">
    <template v-slot:buttons>
      <el-button-group>
        <el-button type="success" size="mini" @click="batchSend()" v-if="hasPermission('shop:batchSend')">
          <i class="el-icon-s-promotion" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('一键发货')}}</span>
          </transition>
        </el-button>
        <el-button type="danger" size="mini" @click="downloadPage()">
          <i class="el-icon-upload2" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('面单下载')}}</span>
          </transition>
        </el-button>
      </el-button-group>
    </template>
    <template slot="tools">
      <el-button-group>
        <el-button type="success" @click="getSimpleProductNames(true)" size="mini">
          <i class="el-icon-search" style="display:inline-block;margin-right:4px; "></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('搜索')}}</span>
          </transition>
        </el-button>
        <el-button type="info" size="mini" @click="expendQuery()">
          <i class="el-icon-arrow-down" v-if="!isQueryExpend" style="display:inline-block;margin-right:4px; "></i>
          <i class="el-icon-arrow-up"  v-else style="display:inline-block;margin-right:4px; "></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('高级')}}</span>
          </transition>
        </el-button>
      </el-button-group>
    </template>
  </vxe-toolbar>
  
  <div class="table-wrap">
    <vxe-table style="width: 100%" :height="docHeight" ref="dataTable"
      border="inner" size="small"  stripe resizable  :data="simpleProductNames" 
      show-overflow 
      highlight-hover-row highlight-current-row highlight-hover-column highlight-current-column
    >
      <vxe-table-column type="checkbox" width="40" :fixed="isMinScreen?null: 'left'"></vxe-table-column>
      <vxe-table-column field="id" :title="msg('ID')"  min-width="90" :fixed="isMinScreen ? null : 'left'" 
          sortable 
      ></vxe-table-column>
      <vxe-table-column field="created" :title="msg('建單時間')" min-width="150" 
          sortable 
      ></vxe-table-column> 
      <vxe-table-column field="orderCode" :title="msg('貨單號')" min-width="150" 
          sortable 
      ></vxe-table-column> 
      <vxe-table-column field="status" :title="msg('物流狀態')" min-width="150" 
          sortable 
      >
        <template v-slot="{ row }">
          <el-tag :type="dicts.cssTypes[row.status]" disable-transitions>
            {{dicts.expStatus[row.status]}}
          </el-tag>
        </template>
      </vxe-table-column> 
      <vxe-table-column field="pagePrintStatus" :title="msg('面單狀態')" min-width="150" 
      >
        <template v-slot="{ row }">
          <el-link :type="dicts.cssTypes[row.pagePrintStatus]" @click="openPdfPage(row.pagePrintStatus, row.expPageId)">
            {{dicts.expPageStatus[row.pagePrintStatus]}}
          </el-link>
        </template>
      </vxe-table-column> 
      <vxe-table-column field="expCompany" :title="msg('配送方式')" min-width="150" 
      ></vxe-table-column>     
      <vxe-table-column field="createrName" :title="msg('創建人')" min-width="150" 
      ></vxe-table-column>    
    </vxe-table>

    <el-pagination id="xpagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :background="true"
      :current-page.sync="currentPage"
      :page-sizes="[10,20 ,50, 100, 200, 500, 1000,2000, 5000,10000,20000,50000]"
      :page-size="query.length"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>

    <el-image-viewer v-if="showViewer" :on-close="()=>{this.showViewer = false}" :url-list="[imgUrl]" />
</div>
</template> 

<script>
// @ is an alias to /src
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
export default {
  name: 'ExpireExpress',
  components: {
    ElImageViewer: ElImageViewer,
  },
  mounted:function(){
    this.getSimpleProductNames();
    var _this = this;
    this.columns = this.$kit.getColumns('exp-spn-filter');
  },
  data(){
    return{
        query:{  //查询条件
          start: 0,
          length: 10,
          expCode: null,
          codeStrs:null,
          Date: [], 
        },
        currentPage: 1,   //分页的当前页
        simpleProductNames: [],   //表格数据列表
        isQueryExpend: this.$store.state.isQueryExpend,  //查询框是否展开
        total: 0,//总记录数,
        currId: null,   //当前列表中选中的id
        dialogTableVisible: false, //编辑详情的隐藏与显示,
        columns: [],  //字段是否显示
        curr:null,
        codeImportVisible:false,
        pdfUrl: this.$kit.api.pdf+"?id=",
        showViewer:false,
        imgUrl: null,
    }
  }, 
  computed: {
    isMinScreen: {  //是否小屏幕，浏览器
        get() {
          return this.$store.state.isMinScreen;
        }
    },
    docHeight: {  //表格的高度
        get() {
          var height =  this.$store.state.docHeight;
          return height - 320;
        }
    }
  },
  methods: {
    hendleOpenedQuery(){
      this.$nextTick(() => {
        this.$refs.queryForm.init();
      });
      
    },
    uploadCode(){
      this.codeImportVisible = true;
    },
    codeImport(){
        this.$nextTick(() => {
        this.$refs.shelfCodeImport.initForm();
      });
    },
    getSimpleProductNames: function(start){ //获取列表
      var _this = this;

      if(this.query.codeStrs){
          let cs = this.query.codeStrs.split(/\s+/);
  
          let css = [];
          for(let i=0; i<cs.length; i++){
              if(cs[i]) css.push(cs[i]);
          }
          this.query["codes"] = css.join(",");
      }else{
          this.query["codes"] = '';
      }

      var param = this.$kit.rebuildQuery(this.query);
      if(start){
        param.start = 0;
        this.currentPage = 1;
      }

      this.axios.post(this.$kit.api.expireExpList, param).then((result) =>{
        if(result.status){
          if(_this.simpleProductNames.length>0)
            _this.simpleProductNames.splice(0, _this.simpleProductNames.length);
          
          _this.total = result.data.recordsTotal;
          _this.simpleProductNames = result.data.data;

        }else{
          _this.$message(result.msg);
        }
        
      }).catch(function (error) {
        console.error(error);
      });

    },//end function
    refresh: function(){  //刷新列表
      this.$kit.clearQuery(this.query);
      this.currentPage = 1;
      this.getSimpleProductNames();
    },
    expendQuery: function(){  //展开与收缩查询框
        this.isQueryExpend = !this.isQueryExpend;
    },
    handleSizeChange(val) {  //分页的每页数据变化后的回调事件
      //console.log(`每页 ${val} 条`);
      this.query.length = val;
      this.query.start = 0;
      this.getSimpleProductNames();
    },
    handleCurrentChange(val) {   //分页的当前页改变后的回调事件
      //console.log(`当前页: ${val}`);
      this.query.start = this.query.length * (val -1);
      this.getSimpleProductNames();
    },
    openPdfPage(status, expPageId){
      if(status==0) return;
      this.imgUrl = this.pdfUrl+expPageId;
      this.showViewer = true;
    },
    batchSend(){
      var selectes = this.$refs.dataTable.getCheckboxRecords();
      if(selectes.length==0){
        this.$message(this.msg('请选中一条数据进行操作'));
        return;
      }
      var _this = this;
      this.$confirm(_this.msg('确定要一键发货吗?'), _this.msg('提示'), {
          confirmButtonText: _this.msg('确定'),
          cancelButtonText: _this.msg('取消'),
          type: 'warning'
        }).then(() => {
          _this.axios.get(_this.$kit.api.batchSend+"?ids="+selectes.map(e => e.id).join(",")).then((result) => {
            if(result.status){ 
                _this.getSimpleProductNames(); 
            }
            _this.$message(result.data);
          }).catch(function (error) {
              console.error(error);
          });
        });
    },
    downloadPage(){
        var selectes = this.$refs.dataTable.getCheckboxRecords();
        if(selectes.length==0){
          this.$message(this.msg('请选中一条数据进行操作'));
          return;
        }
        var _this = this;
        this.$confirm(_this.msg('确定要下载面单吗?'), _this.msg('提示'), {
            confirmButtonText: _this.msg('确定'),
            cancelButtonText: _this.msg('取消'),
            type: 'warning'
          }).then(() => {
            var ids = selectes.map(e => e.id).join(",");
            window.open(this.$kit.api.pagePicExport + "?ids=" + ids, "_blank");
            // _this.axios.get(_this.$kit.api.pagePicExport+"?ids="+selectes.map(e => e.id).join(",")).then((result) => {
            //   if(result.status){ 
            //       _this.getSimpleProductNames(); 
            //   }
            //   _this.$message(result.data);
            // }).catch(function (error) {
            //     console.error(error);
            // });
          });
    }
  }
}
</script>

<style class="scss" scoped>


</style>
