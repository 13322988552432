<!-- 物流信息导入更新 -->
<template>
<div class="content-panel">
  <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
    <el-col :span="20">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">{{msg('首页')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{msg('物流导入更新')}}</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>
  </el-row>

  <el-row :gutter="10" type="flex" justify="space-between" align="center" class="ctrl-wrap">
    <el-col :span="8">
      <el-select v-model="uploadUrl" class="query-select" placeholder="打包类型" size="small" clearable>
        <el-option v-for="item in types" :key="item.url"
          :label="item.label" :value="item.url" v-show="hasPermission(item.permission)"
        ></el-option>
      </el-select>
    </el-col>
    <el-col :span="8">
        <el-upload class="upload-demo" :with-credentials="true" multiple
            :file-list="fileList"
            :action="uploadUrl" :on-success="handleSuccess">
              <el-button size="small" icon="el-icon-upload2" type="primary">{{msg('选择文件')}}</el-button>
        </el-upload>
    </el-col>
    <el-col :span="8" style="text-align:right;">
        <el-dropdown  @command="clickProcessItem" >
          <el-button type="primary" size="mini" icon="el-icon-upload">
            <transition name="el-fade-in-linear">
              <span v-show="!isMinScreen">{{msg('模板下载')}}</span>
            </transition>
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="download1">{{msg('通用模板下载')}}</el-dropdown-item>
            <el-dropdown-item command="download2">{{msg('扩展模板下载')}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
    </el-col>
  </el-row>

  <div class="table-wrap">
    <el-table :data="results" style="width: 100%" height="400px" size="mini" ref="dataTable" stripe>
      <el-table-column type="index" width="50"></el-table-column>
      <el-table-column prop="key" :label="msg('订单号')" min-width="120" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column v-if="uploadUrl.indexOf('importPkgWithEditor')>-1" prop="pkgCode" :label="msg('包号')" min-width="120" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column v-else prop="value" :label="msg('数据')" min-width="120" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="result" :label="msg('上传结果')" min-width="200"></el-table-column>
    </el-table>
  </div>
  <el-row :gutter="10" type="flex" justify="start" align="center" class="ctrl-wrap">
    <el-col :span="24">
        <el-alert title="excel模板：第一列为货单号，第二列为要更新的值"  type="warning"></el-alert>
        <el-alert title="目前仅支持更新重量和配送状态"  type="warning"></el-alert>
    </el-col>
  </el-row>
</div>
</template> 

<script>
// @ is an alias to /src

export default {
  name: 'ExpImportUpdate',
  components: {},
  mounted:function(){
    var _this = this;
  },
  data(){
    return{
      uploadUrl: this.$kit.api.expImportUpdate+"?type=1",

      types: [
        {label: this.msg("更新重量"), url: this.$kit.api.expImportUpdate+"?type=1", permission: "express:updateWeight", type: 1},
        {label: this.msg("更新配送状态"), url: this.$kit.api.expImportUpdate+"?type=2", permission: "express:updateOrderStatus", type: 2},
        {label: this.msg("更新查询码"), url: this.$kit.api.expImportUpdate+"?type=3", permission: "express:updateExpCode", type: 3},
        {label: this.msg("更新货单备注"), url: this.$kit.api.expImportUpdate+"?type=4", permission: "express:updateRemark", type: 4},
        {label: this.msg("更新品名"), url: this.$kit.api.expImportUpdate+"?type=5", permission: "express:updateGoodName", type: 5},
        {label: this.msg("更新出货批次"), url: this.$kit.api.expImportUpdate+"?type=6", permission: "express:updateRemark1", type: 6},
        {label: this.msg("更新报单备注"), url: this.$kit.api.expImportUpdate+"?type=7", permission: "express:updateRemark2", type: 7},
        {label: this.msg("资料员导入包号"), url: this.$kit.api.expImportPkgWithEditor, permission: "expPkg:importPkgWithEditor"},
        {label: this.msg("更新新增字段"), url: this.$kit.api.expImportPayment, permission: "express:importExpressPayment"},
        {label: this.msg("更新托运状态"), url: this.$kit.api.expImportUpdate+"?type=8", permission: "express:updateRemark3", type: 8},
      ],

      results: [],   //表格数据列表
      fileList: []
    }
  }, 
  computed: {
    isMinScreen: {  //是否小屏幕，浏览器
      get() {
        return this.$store.state.isMinScreen;
      }
    },
    docHeight: {  //表格的高度
      get() {
        var height =  this.$store.state.docHeight;
        return height - 320;
      }
    }
  },
  methods: {
    initForm(){
        this.results.splice(0);
        this.fileList.splice(0);
    },
    handleSuccess(res, file, fileList) {
        if(res.status){
            var datas = res.data;
            datas.forEach(a => this.results.push(a));
        }else{
          this.$message(res.msg || this.msg("上传失败"));
        }
    },
    clickProcessItem(command){ 

      if(command=='download1'){
          window.location.href= this.$kit.api.expUpdateDownload;
      }else if(command == 'download2'){
          window.location.href= this.$kit.api.expPaymentDownload;
      }
    },
  }
}
</script>

<style class="scss" scoped>


</style>
