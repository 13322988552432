import { render, staticRenderFns } from "./ExpEdit.vue?vue&type=template&id=31680d1c&scoped=true"
import script from "./ExpEdit.vue?vue&type=script&lang=js"
export * from "./ExpEdit.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31680d1c",
  null
  
)

export default component.exports