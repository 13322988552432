<template>
<el-form ref="form" :model="exp" :rules="rules" label-width="100px">
    <h3>物流信息</h3>
    <el-row>
        <el-col :xs="24">
            <el-form-item :label="msg('订单编号')">
                <div style="display:flex;">
                    <el-input v-model="exp.orderCode" style="flex: 0 0 280px;" type="text"></el-input>
                    <span style="margin-left:10px;">{{msg('订单编号可填可不填，如不填系统会自动生成')}}</span>
                </div>
            </el-form-item>
        </el-col>
    </el-row>
    <el-row>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('收件人')" prop="receiver">
                <el-input v-model="exp.receiver" type="text"></el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('收件人电话')" prop="receiverTel">
                <el-input v-model="exp.receiverTel" type="text"></el-input>
            </el-form-item>
        </el-col>
    </el-row>
    <el-form-item :label="msg('收件人地址')" prop="receiverAddr">
        <el-input v-model="exp.receiverAddr" type="text"></el-input>
    </el-form-item>
    <el-row>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('门店号区码')" >
                <el-input v-model="exp.shopCode" type="text"></el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('品名')" prop="goodName">
                <el-input v-model="exp.goodName" type="text"></el-input>
            </el-form-item>
        </el-col>
    </el-row>
    <el-row>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('代收款')" prop="amount">
                <el-input v-model="exp.amount" type="number"></el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('物流厂商')">
                <el-select v-model="exp.expCompany" prop="expCompany">
                    <el-option v-for="(d, idx) in dicts.expCompanies1" 
                        :label="d.label" :value="d.value" :key="idx">
                    </el-option>
                </el-select>
            </el-form-item>
        </el-col>
    </el-row>
    <el-row>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('物流状态')">
                <el-select v-model="exp.status" :disabled="true">
                    <el-option v-for="(d, index) in dicts.expStatus" :key="index" :label="d" :value="index"></el-option>
                </el-select>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('面单打印状态')">
                <el-select v-model="exp.pagePrintStatus" :disabled="true">
                    <el-option v-for="ps in dicts.expPageStatus1" :key="ps.value" :label="ps.label" :value="ps.value"></el-option>
                </el-select>
            </el-form-item>
        </el-col>
    </el-row>
    <el-row>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('包裹类型')">
                <el-select v-model="exp.hasStorePkg" :disabled="true">
                    <el-option v-for="d in dicts.expPkgTypes1" :key="d.value" :label="d.label" :value="d.value"></el-option>
                </el-select>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('能否列印面单')" label-width="140">
                <el-switch :disabled="true"
                    v-model="exp.allowUploadPage"
                    active-text="不允许" inactive-text="允许"
                    :active-value="1" :inactive-value="0"
                >
                </el-switch>
            </el-form-item>
        </el-col>
    </el-row>
    <el-row>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('订单来源')">
                <el-select v-model="exp.pageType" :disabled="true">
                    <el-option v-for="d in dicts.expOrigins1" :key="d.value" :label="d.label" :value="d.value"></el-option>
                </el-select>
            </el-form-item>
        </el-col>
    </el-row>
     <el-row>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('备注')">
                <el-input v-model="exp.remark" type="textarea" :rows="3"></el-input>
            </el-form-item>
        </el-col>
    </el-row>

    <h3>订单信息</h3>
    <el-table :data="exp.orders" style="width: 100%; padding-right:20px;"
            size="mini" ref="dataTable" stripe>
        <el-table-column type="index" width="50" label="序号"></el-table-column>
        <el-table-column label="商品名" min-width="150">
            <template slot-scope="scope">
                <el-input v-model="scope.row.name" size="mini" ></el-input>
            </template>
        </el-table-column>
        <el-table-column label="国内快递单号" min-width="150">
            <template slot-scope="scope">
                <el-input v-model="scope.row.innerExpCode" size="mini" ></el-input>
            </template>
        </el-table-column>
        <el-table-column label="规格" min-width="150">
            <template slot-scope="scope">
                <el-input v-model="scope.row.spec" size="mini" ></el-input>
            </template>
        </el-table-column>
        <el-table-column label="件数" min-width="80">
            <template slot-scope="scope">
                <el-input v-model="scope.row.qty" size="mini"></el-input>
            </template>
        </el-table-column>
        <el-table-column label="店长名" min-width="120">
            <template slot-scope="scope">
                <el-input v-model="scope.row.shopOwner" size="mini"></el-input>
            </template>
        </el-table-column>
        <el-table-column label="备注" min-width="150">
            <template slot-scope="scope">
                <el-input v-model="scope.row.remark" size="mini"></el-input>
            </template>
        </el-table-column>
        <el-table-column label="操作" min-width="80" v-if="!hasView">
            <template slot-scope="scope">
                <el-button type="danger" class="nopad" icon="el-icon-delete" size="mini" plain
                    @click.stop="exp.orders.splice(scope.$index, 1)">
                </el-button>
            </template>
        </el-table-column>
    </el-table>
    <div style="text-align:center; margin-top:5px;" v-if="!hasView">
        <el-button type="primary" size="mini" plain @click="addOrders()">
            <i class="el-icon-circle-plus-outline"></i>添加更多
        </el-button>
    </div>
    
    <div style="text-align:center; margin-top:20px;">
        <el-button type="primary" size="mini" v-if="!hasView" @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
        <el-button  size="mini" v-on:click="$emit('closeDg')">{{msg('取消')}}</el-button>
    </div>
</el-form> 
</template>

<script>
export default {
  name: 'ExpEdit',
  data(){
    return{
        rules: {
            receiver: [
                { required: true, message: '请输入收件人', trigger: 'blur' },
            ],
            receiverTel: [
                { required: true, message: '请输入收件人电话', trigger: 'blur' },
            ],
            receiverAddr: [
                { required: true, message: '请输入收件人地址', trigger: 'blur' },
            ],
            goodName: [
                { required: true, message: '请输入品名', trigger: 'blur' },
            ],
            amount: [
                { required: true, message: '请输入代收款', trigger: 'blur' },
            ],
            expCompany: [
                { required: true, message: '请选择物流厂商', trigger: 'change' },
            ],
       },
    }
    
  }, 
  computed:{
      mode: {
          get(){
              return this.id==null;
          }
      },
      isEdit: {
        get(){
            return this.id==null;
        }
    }
  },
  props:{
      id:null,
      exp:Object,
      hasView: Boolean,
  },
  mounted: function(){
    var _this = this;
    var user = JSON.parse(window.localStorage.getItem("user"));
    this.userName = user.username;
  },
  methods:{
 
    onSubmit(){
        var _this = this;
        this.$refs["form"].validate((valid) => {
            if (valid) {
                this.exp.orders[0].price = this.exp.amount;
                if(!this.exp.orders[0].name){
                    this.exp.orders[0].name = this.exp.goodName;
                }
                this.$axios.post(this.$kit.api.expLineCreate, this.exp)
                .then(function(result){
                    if(result.status){
                        _this.$alert(result.msg || (_this.id==null? _this.msg("创建成功"): _this.msg('更新成功')), _this.msg('提示'), {
                            confirmButtonText: _this.msg('确定'),
                            callback: action => {
                                if(result.status){
                                    _this.$emit("closeDg", true);
                                }
                            }
                        });
                    }else{
                        _this.$message(result.msg || (_this.id==null? _this.msg('创建失败'):_this.msg('更新失败')));
                    }
                
                });
            } else {
                _this.$message({
                    message: '请完善信息后，在提交',
                    type: 'warning'
                });
                return false;
            }
        });
    },
    addOrders(){
        if(!this.exp.amount){
            this.$message('请输入代收款');
            return;
        }

        var order = {
            name: this.exp.goodName,
            spec: null,
            qty: 1,
            price:this.exp.amount,
            shopOwner: null,
            remark: null
        }

        order.code = this.exp.orderCode;
        if(this.exp.orders.length>0){
            order.shopOwner = this.exp.orders[0].shopOwner;
        }

        this.exp.orders.push(order);

    },

  }
}
</script>

<style class="scss" scoped>


</style>
