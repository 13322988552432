<template>
<el-form ref="form" :model="exp" :rules="rules" label-width="100px">
    <h3>物流信息</h3>
    <el-row>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('订单编号')">
                <el-input v-model="exp.orderCode" :disabled="!isEdit"></el-input>
            </el-form-item>
        </el-col>
        
    </el-row>
    <el-row>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('物流厂商')">
                <el-select v-model="exp.expCompany">
                    <el-option v-for="(d, idx) in dicts.expCompanies" 
                        :label="d.label" :value="d.value" :key="idx">
                    </el-option>
                </el-select>
                <!-- <el-checkbox v-if="!hasView" v-model="exp.updateAllCompany">同时更新所有同名物流信息</el-checkbox> -->
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
                <el-form-item :label="msg('品名')">
                    <el-input v-model="exp.goodName" type="text"></el-input>
                    <el-checkbox v-if="!hasView" v-model="exp.updateAllName">同时更新所有同名物流信息</el-checkbox>
                </el-form-item>
        </el-col>
    </el-row>
    <el-row>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('收件人')">
                <el-input v-model="exp.receiver" type="text"></el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('收件人电话')">
                <el-input v-model="exp.receiverTel" type="text"></el-input>
            </el-form-item>
        </el-col>
    </el-row>
    <el-form-item :label="msg('收件人地址')">
        <el-input v-model="exp.receiverAddr" type="text"></el-input>
    </el-form-item>
    <el-row>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('物流状态')">
                <el-select v-model="exp.status" :disabled="true">
                    <el-option v-for="(d, index) in dicts.expStatus" :key="index" :label="d" :value="index"></el-option>
                </el-select>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('面单打印状态')">
                <el-select v-model="exp.pagePrintStatus" :disabled="true">
                    <el-option v-for="ps in dicts.expPageStatus1" :key="ps.value" :label="ps.label" :value="ps.value"></el-option>
                </el-select>
            </el-form-item>
        </el-col>
    </el-row>
    <el-row>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('配送状态')">
                <el-input v-model="exp.orderStatus" type="text" :disabled="true"></el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('是否可以上传面')" label-width="140">
                <el-switch :disabled="true"
                    v-model="exp.allowUploadPage"
                    active-text="不允许" inactive-text="允许"
                    :active-value="1" :inactive-value="0"
                >
                </el-switch>
            </el-form-item>
        </el-col>
    </el-row>
    <el-row>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('面单码')">
                <el-input v-model="exp.expPageCode" type="text"></el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('台湾物流码')">
                <el-input v-model="exp.twExpCode" type="text"></el-input>
            </el-form-item>
        </el-col>
    </el-row>
     <el-row>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('备注')">
                <el-input v-model="exp.remark" type="textarea" :rows="3"></el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('报单备注1')">
                <el-input v-model="exp.remark1" type="textarea" :rows="3"></el-input>
            </el-form-item>
        </el-col>
    </el-row>
    <el-row>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('报单备注2')">
                <el-input v-model="exp.remark2" type="textarea" :rows="3"></el-input>
            </el-form-item>
        </el-col>
    </el-row>
    
    <h3>物流装袋信息</h3>
    <el-row>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('袋号')">
                <el-input v-model="exp.expPkg.pkgCode" type="text"></el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" v-if="exp.expPkg.hasSend">
            <el-form-item :label="msg('是否发货')">
                <el-switch :disabled="true"
                    v-model="exp.expPkg.hasSend"
                    active-text="未发货" inactive-text="已发货"
                    :active-value="1" :inactive-value="0"
                >
                </el-switch>
            </el-form-item>
        </el-col>
    </el-row>
    <el-alert type="warning" title="袋号/临时袋号必须二选一" center></el-alert> 
    <el-row v-if="exp.expPkg.packaged || exp.expPkg.sendDate">
        <el-col :xs="24" :sm="12" v-if="exp.expPkg.packaged">
            <el-form-item :label="msg('打包时间')">
                <el-input v-model="exp.expPkg.packaged" type="text" :disabled="true"></el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12"  v-if="exp.expPkg.sendDate">
            <el-form-item :label="msg('发货时间')">
                <el-input v-model="exp.expPkg.sendDate" type="text" :disabled="true"></el-input>
            </el-form-item>
        </el-col>
    </el-row>
    <el-row v-if="exp.expPkg.zhuangchuanDate || exp.expPkg.qingguanDate">
        <el-col :xs="24" :sm="12" v-if="exp.expPkg.zhuangchuanDate">
            <el-form-item :label="msg('离境时间')">
                <el-input v-model="exp.expPkg.zhuangchuanDate" type="text" :disabled="true"></el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12"  v-if="exp.expPkg.qingguanDate">
            <el-form-item :label="msg('清关时间')">
                <el-input v-model="exp.expPkg.qingguanDate" type="text" :disabled="true"></el-input>
            </el-form-item>
        </el-col>
    </el-row>
    <el-row v-if="exp.expPkg.pickUpDate">
        <el-col :xs="24" :sm="12" v-if="exp.expPkg.pickUpDate">
            <el-form-item :label="msg('提货时间')">
                <el-input v-model="exp.expPkg.pickUpDate" type="text" :disabled="true"></el-input>
            </el-form-item>
        </el-col>
    </el-row>
    
    <h3><i class="el-icon-warning"></i>订单信息</h3>
    <el-table :data="exp.orders" style="width: 100%; padding-right:20px;"
            size="mini" ref="dataTable" stripe>
        <el-table-column type="index" width="50" label="序号"></el-table-column>
        <el-table-column prop="id" label="ID" min-width="50" ></el-table-column>
        <el-table-column prop="code" label="订单编号" min-width="120" ></el-table-column>
        <el-table-column label="商品名" min-width="150">
            <template slot-scope="scope">
                <el-input v-model="scope.row.name" size="mini" ></el-input>
            </template>
        </el-table-column>
        <el-table-column label="国内快递单号" min-width="180">
            <template slot-scope="scope">
                <el-input v-model="scope.row.innerExpCode" size="mini"></el-input>
            </template>
        </el-table-column>
        <el-table-column label="规格" min-width="100">
            <template slot-scope="scope">
                <el-input v-model="scope.row.spec" size="mini" ></el-input>
            </template>
        </el-table-column>
        <el-table-column label="件数" min-width="80">
            <template slot-scope="scope">
                <el-input v-model="scope.row.qty" size="mini"></el-input>
            </template>
        </el-table-column>
        <el-table-column label="店长名" min-width="120">
            <template slot-scope="scope">
                <el-input v-model="scope.row.shopOwner" size="mini"></el-input>
            </template>
        </el-table-column>
        <el-table-column label="备注" min-width="150">
            <template slot-scope="scope">
                <el-input v-model="scope.row.remark" size="mini"></el-input>
            </template>
        </el-table-column>
        <el-table-column label="操作" min-width="80" v-if="!hasView">
            <template slot-scope="scope">
                <el-button type="danger" class="nopad" icon="el-icon-delete" size="mini" plain
                    @click.stop="exp.orders.splice(scope.$index, 1)">
                </el-button>
            </template>
        </el-table-column>
    </el-table>
    <div style="text-align:center; margin-top:5px;" v-if="!hasView">
        <el-button type="primary" size="mini" plain @click="addOrders()">
            <i class="el-icon-circle-plus-outline"></i>添加更多
        </el-button>
    </div>
    
    <div style="text-align:center; margin-top:20px;">
        <el-button type="primary" size="mini" v-if="!hasView" @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
        <el-button  size="mini" v-on:click="$emit('close-dg')">{{msg('取消')}}</el-button>
    </div>
</el-form> 
</template>

<script>
export default {
  name: 'ExpEdit',
  data(){
    return{
        rules: {
            expCode: [
                { required: true, message: '请输入订单编号', trigger: 'blur' },
            ],
            expCompany: [
                { required: true, message: '请输入物流厂商', trigger: 'blur' },
            ],
            goodName: [
                { required: true, message: '请输入品名', trigger: 'blur' },
            ],
            receiver: [
                { required: true, message: '请输入收件人', trigger: 'blur' },
            ],
            receiverTel: [
                { required: true, message: '请输入收件人电话', trigger: 'blur' },
            ]
       },
       isDisabled:true,
    }
    
  }, 
  computed:{
      mode: {
          get(){
              return this.id==null;
          }
      },
      isEdit: {
        get(){
            return this.id==null;
        }
    },
    // isDisabled:{
    //     get(){
    //         return this.exp.status > 0;
    //     }
    // }
  },
  props:{
      id:null,
      exp:Object,
      hasView: Boolean,
  },
  mounted: function(){
    var _this = this;
    var user = JSON.parse(window.localStorage.getItem("user"));
    this.userName = user.username;
  },
  methods:{
    init(){
    },
    onSubmit(){
        var url = null;
        if(this.id==null){
            url = this.$kit.api.expCreate;
        }else{
            url = this.$kit.api.expUpdate;
        }

        var expPkg = this.exp.expPkg;

        this.exp.expPkg = {};

        if(expPkg.id){
            this.exp.expPkg['id'] = expPkg.id;
        }

        if(expPkg.pkgCode){
            this.exp.expPkg['pkgCode'] = expPkg.pkgCode;
        }

        var Regx = /^[A-Za-z0-9]*$/;
        let res = true;
        if(this.exp.orders.length > 0){
            this.exp.orders.forEach(order => {
                if(order.innerExpCode){
                    if(!Regx.test(order.innerExpCode)){
                        res = false;
                    }
                }
            });
        }
        if(!res){
            this.$message({message:this.msg('国内快递单号只能是字母或数字！'),type: 'error'});
            return;
        }

        var _this = this;
        this.$axios.post(url, this.exp)
            .then(function(result){
                if(result.status){
                    _this.$alert(result.msg || (_this.id==null? _this.msg("创建成功"): _this.msg('更新成功')), _this.msg('提示'), {
                        confirmButtonText: _this.msg('确定'),
                        callback: action => {
                            if(result.status){
                                _this.$emit("closeDg", true);
                            }
                        }
                    });
                }else{
                    _this.$message(result.msg || (_this.id==null? _this.msg('创建失败'):_this.msg('更新失败')));
                }
               
            });
    },
    addOrders(){
        if(!this.exp.orderCode){
            this.$message("请输入订单编号！");
            return;
        }

        var order = {
            code: null,
            name: null,
            spec: null,
            qty: 1,
            price:0,
            shopOwner: null,
            remark: null
        }

        order.code = this.exp.orderCode;
        if(this.exp.orders.length>0){
            order.shopOwner = this.exp.orders[0].shopOwner;
        }

        this.exp.orders.push(order);

    },
    handleRemove(file, fileList) {
        console.log(file, fileList);
    },
    handlePreview(file) {
        this.exp.expPageId = null;
        this.exp.expPages.splice(0);
    },
    beforeRemove(file, fileList) {
        this.$confirm('确定移除该面单吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        }).catch(() => {
                  
        });

    },
    handleSuccess(res, file, fileList) {
        if(res.status){
            this.exp.expPageId = res.data;
            this.exp.expPages.push(this.$kit.api.imgView+"?id="+res.data)
        }else{
            this.$message(res.msg || this.msg("上传失败"));
        }
    },

  }
}
</script>

<style class="scss" scoped>


</style>
