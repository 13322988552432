<!-- 物流信息批量更新指定的字段 -->
<template>
<div class="content-panel">
  <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
    <el-col :span="20">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">{{msg('首页')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{msg('物流批量更新')}}</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>
  </el-row>

  <el-row :gutter="10" type="flex" justify="space-between" align="center" class="ctrl-wrap">
    <el-col :span="10">
      <el-select v-model="updateUrl" class="query-select" placeholder="打包类型" size="small" clearable>
        <el-option v-for="item in options" :key="item.url"
          :label="item.label" :value="item.url" v-show="hasPermission(item.permission)"
        ></el-option>
      </el-select>
    </el-col>
    <el-col :span="14">
      <el-input :placeholder="msg('输入要填充的值')" v-model="input1" class="input-with-select" size="small">
        <template slot="prepend">{{msg('批量填充：')}}</template>
        <el-button slot="append" icon="el-icon-check" @click="fillValue"></el-button>
      </el-input>
    </el-col>
  </el-row>

  <div class="table-wrap">
    <el-table :data="datas" style="width: 100%" height="400px" size="mini" ref="dataTable" stripe>
      <el-table-column type="index" width="50"></el-table-column>
      <el-table-column prop="key" :label="msg('订单编号')" min-width="120" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="value" :label="msg('数据')" min-width="150" >
        <template slot-scope="scope">
          <el-input :placeholder="msg('输入要填充的值')" v-model="scope.row.value" size="small">
          </el-input>
        </template>
      </el-table-column>
      <el-table-column prop="result" :label="msg('上传结果')" min-width="200"></el-table-column>
    </el-table>
  </div>
  <div style="text-align:center;">
    <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
    <el-button  size="mini" v-on:click="$emit('closeDg')">{{msg('取消')}}</el-button>
  </div>
</div>
</template> 

<script>
// @ is an alias to /src

export default {
  name: 'ExpBatchUpdateField',
  components: {},
  mounted:function(){
    var _this = this;
  },
  data(){
    return{

      options: [
        {label: this.msg("更新重量"), url: this.$kit.api.expUpdateField+"?type=1", permission: "express:updateWeight", type: 1},
        {label: this.msg("更新配送状态"), url: this.$kit.api.expUpdateField+"?type=2", permission: "express:updateOrderStatus", type: 2},
        {label: this.msg("更新查询码"), url: this.$kit.api.expUpdateField+"?type=3", permission: "express:updateExpCode", type: 3},
        {label: this.msg("更新备注"), url: this.$kit.api.expUpdateField+"?type=4", permission: "express:updateRemark", type: 4},
        {label: this.msg("更新品名"), url: this.$kit.api.expUpdateField+"?type=5", permission: "express:updateGoodName", type: 5},
        {label: this.msg("更新报单备注1"), url: this.$kit.api.expUpdateField+"?type=6", permission: "express:updateRemark1", type: 6},
        {label: this.msg("更新报单备注2"), url: this.$kit.api.expUpdateField+"?type=7", permission: "express:updateRemark2", type: 7},
      ],

      updateUrl: this.$kit.api.expUpdateField+"?type=1",
      
      input1: null,

    }
  }, 
  props:{
    datas: Array
  },
  computed: {
    isMinScreen: {  //是否小屏幕，浏览器
        get() {
          return this.$store.state.isMinScreen;
        }
    },
    docHeight: {  //表格的高度
        get() {
          var height =  this.$store.state.docHeight;
          return height - 320;
        }
    }
  },
  methods: {
    fillValue(){
      if(this.input1 && this.datas){
        this.datas.forEach(e => e["value"] = this.input1);
      }
    },
    onSubmit(){
      var _this = this;
      if(!this.updateUrl){
        this.$message("请选择更新类型");
      }

      this.$axios.post(this.updateUrl, this.datas)
        .then((result) => {
          _this.$message(result.msg);
          _this.datas.splice(0,_this.datas);
          result.data.forEach(e => _this.datas.push(e));
        });
    }
   
  }
}
</script>

<style class="scss" scoped>


</style>
